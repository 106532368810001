<template>
  <main class="login-body">
    <LoginForm @showConfirmForm="showConfirmForm" @resetPassword="resetPassword" v-if="openLoginForm" />
    <ResetPassword @backToLogin="backToLogin" v-if="openResetPassword" />
    <ConfirmSlAreaUsers @backToLogin="backToLogin" v-if="openConfirm" />
  </main>
</template>

<script>
import LoginForm from '@/components/registration/LoginForm.vue';
import ResetPassword from '@/components/registration/ResetPassword.vue';
import ConfirmSlAreaUsers from '@/components/registration/ConfirmSlAreaUsers.vue';
import intercom from '../utils/intercom';

export default {
  name: 'Login',
  components: {
    LoginForm,
    ResetPassword,
    ConfirmSlAreaUsers
  },
  data () {
    return {
      openLoginForm: true,
      openConfirm: false,
      openResetPassword: false
    };
  },
  created () {
    intercom();

    const token = this.$route.query.token;
    if (token) {
      this.openLoginForm = false;
      this.openResetPassword = true;
    }
  },
  methods: {
    showConfirmForm () {
      this.openLoginForm = false;
      this.openConfirm = true;
    },
    resetPassword () {
      this.openLoginForm = false;
      this.openResetPassword = true;
    },
    backToLogin () {
      this.openResetPassword = false;
      this.openConfirm = false;
      this.openLoginForm = true;
    }
  }
};
</script>

<template>
  <div class="px-8">
    <div v-if="showEmailCodeForm">
      <h3 class="typography-1 color-black">Enter the code</h3>
      <div class="typography-11 color-2 mb-24">Enter the code you receive by {{emailToConfirm || accountState.email}}</div>
      <div class="input-block mb-12">
        <input
          id="confirm-email-code"
          type="text"
          placeholder="Enter the code"
          class="input bg-form"
          :class="errors.emailCode && v$.emailCode.$error ? 'error-border' : ''"
          v-model.trim="v$.emailCode.$model"
          maxlength="6"
        >
        <span class="input-block__counter">{{v$.emailCode.$model.length}} / {{v$.emailCode.maxLength.$params.max}}</span>
      </div>
      <div class="error" v-if="errors.emailCode && v$.emailCode.$error">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.emailCode.minLength.$invalid">
            Code must have at least {{v$.emailCode.minLength.$params.min}} letters.
          </span>
          <span v-show="v$.emailCode.maxLength.$invalid">
            Code must have no more than {{v$.emailCode.maxLength.$params.max}} letters.
          </span>
          <span v-show="v$.emailCode.numeric.$invalid">Code must be numeric</span>
        </div>
      </div>
      <div class="error" v-if="emailError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">{{emailError}}</div>
      </div>
      <div class="d-flex flex-column mt-24">
        <span class="typography-6 color-2 mb-16" v-if="emailTimeout">{{emailTimeout}}</span>
        <div class="d-flex justify-between align-center">
          <span class="typography-6 color-2" v-if="successfulSendCode">{{successfulSendCode}}</span>
          <span class="typography-6 color-2" v-else>Did not get the code?</span>
          <a
            id="send-email-code-again"
            href="#"
            class="results-btn fw-600 h-auto p-0"
            @click.prevent="sendCodeAgain"
            v-if="!timer"
          >
            Send again
          </a>
          <span class="results-btn fw-600 h-auto p-0" v-else>{{timer}} seconds</span>
        </div>
      </div>
      <div class="d-flex mt-32 mb-32">
        <button class="opacity-btn mr-16" @click="backToEmail" id="back-to-email">
          <Icon name="arrow-left-blue" class="mr-8" />
          Wrong email?
        </button>
        <button id="confirm-email" class="primary-btn typography-base" :disabled="!emailCode" @click="sendConfirmEmail">
          Confirm email
        </button>
      </div>
    </div>
    <div v-if="showChangeEmailForm">
      <div class="mb-12">
        <h3 class="typography-1 color-black">Please enter your business email</h3>
        <div class="typography-11 color-2 mb-24">You will receive a code via Email</div>
        <label for="email" class="typography-caption color-2 mb-8 let-space-08">Your business email</label>
        <input type="email" placeholder="Email" id="email" class="input bg-form" :class="errors.email && v$.email.$error ? 'error-border' : ''" v-model.trim="v$.email.$model">
      </div>
      <div class="error" v-if="errors.email && v$.email.$error">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.email.maxLength.$invalid">Email must have no more than {{v$.email.maxLength.$params.max}} letters.</span>
          <span v-show="v$.email.email.$invalid">Wrong email address</span>
        </div>
      </div>
      <div class="error" v-if="emailError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="emailError">{{emailError}}</span>
        </div>
      </div>
      <div class="d-flex mt-32 mb-32">
        <button id="back-to-login" class="opacity-btn mr-16" @click="backToLogin">
          <Icon name="arrow-left-blue" class="mr-8" />
          Back
        </button>
        <button
          id="send-email-code"
          class="primary-btn typography-base"
          :disabled="!email || loading"
          @click="changeEmailAction"
        >
          Send me code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../app/Icon.vue';
import { minLength, maxLength, email, numeric } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'ConfirmEmailForm',
  components: {
    Icon
  },
  data () {
    return {
      email: '',
      emailCode: '',
      loading: false,

      v$: useVuelidate(),
      errors: {
        email: false,
        emailCode: false
      },
      timer: null
    };
  },
  validations () {
    return {
      email: {
        email,
        maxLength: maxLength(320)
      },
      emailCode: {
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric
      }
    };
  },
  emits: ['backToLogin'],
  computed: {
    ...mapGetters(['emailTimeout', 'emailError', 'accountState', 'showEmailCodeForm', 'showChangeEmailForm', 'showConfirmPhoneBlock', 'emailToConfirm', 'showPhoneCodeForm', 'successfulSendCode'])
  },
  methods: {
    checkErrorsEmailCode () {
      this.errors.emailCode = false;
      this.errors.emailCode = this.v$.emailCode.$error;
      if (this.errors.emailCode) {
        return 'error';
      }
    },
    async sendConfirmEmail () {
      const checkErrors = this.checkErrorsEmailCode();
      if (checkErrors === 'error') return;

      this.loading = true;
      try {
        await this.$store.dispatch('sendConfirmEmail', { code: this.emailCode });
        if (!this.emailError) {
          if (this.showConfirmPhoneBlock && this.showPhoneCodeForm) {
            this.$store.dispatch('sendConfirmPhone');
          }
          if (!this.showConfirmPhoneBlock) {
            await this.$store.dispatch('checkTrial');
            await this.$store.dispatch('createNewCase');
            window.roistat.event.send('gamayunreg');
          }
          this.$store.commit('setShowConfirmEmailBlock', false);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async sendCodeAgain () {
      try {
        this.checkTimer();
        this.$store.commit('setSuccessfulSendCode', null);
        await this.$store.dispatch('sendConfirmEmail');
        this.$store.commit('setSuccessfulSendCode', 'The code has been successfully sent');
      } catch (e) {
        console.error(e);
      }
    },
    async changeEmailAction () {
      try {
        this.loading = true;
        this.$store.commit('setErrorEmail', '');
        const payload = {
          method: 'PATCH',
          url: '/api/v2/user',
          body: {
            email: this.email
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('setShowEmailCodeForm', true);
        this.$store.commit('setShowChangeEmailForm', false);
        this.$store.dispatch('sendConfirmEmail');
        this.$store.commit('setEmail', this.email);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const res = error.response;
        if (res.data.fields.email && res.data.fields.email.length) {
          this.$store.commit('setErrorEmail', res.data.fields.email[0].detail);
        } else {
          this.$store.commit('setErrorEmail', 'Something went wrong, try again later');
        }
      }
    },
    backToLogin () {
      this.$store.commit('setShowEmailCodeForm', false);
      this.$store.commit('setShowChangeEmailForm', false);
      this.$store.commit('setErrorEmail', '');
      this.$emit('backToLogin');
    },
    backToEmail () {
      this.$store.commit('setShowEmailCodeForm', false);
      this.$store.commit('setShowChangeEmailForm', true);
    },
    checkTimer () {
      this.timer = 40;
      const timerId = setInterval(() => {
        --this.timer;
      }, 1000);
      setTimeout(() => { clearInterval(timerId); this.timer = null; }, 40000);
    }
  }
};
</script>

<template>
  <div class="px-8">
    <h1 class="typography-1 color-black" data-type="sign-in-header">Sign in</h1>
    <div class="typography-11 color-2 mb-32">
      Don't have an account yet?
      <router-link to="/registration" class="color-link" id="sign-up">Sign up</router-link>
    </div>
    <div class="d-flex flex-column mb-12">
      <label for="email" class="typography-caption color-2 mb-8 let-space-08">Email</label>
      <input
        type="text"
        id="email"
        placeholder="example@yourcompanyname.com"
        class="input bg-form"
        :class="errors.email && v$.email.$error ? 'error-border' : ''"
        v-model.trim="v$.email.$model"
      >
    </div>
    <div class="error" v-if="errors.email && v$.email.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.email.maxLength.$invalid">
          Company must have no more than {{v$.email.maxLength.$params.max}} letters.
        </span>
        <span v-show="v$.email.email.$invalid">Wrong email address</span>
      </div>
    </div>
    <div class="d-flex justify-between">
      <label for="password" class="typography-caption color-2 mb-8 let-space-08">Password</label>
    </div>
    <div class="input-block mb-12">
      <input
        id="sign-in-password"
        type="password"
        :placeholder="'At least ' + v$.password.minLength.$params.min + ' characters'"
        class="input bg-form"
        :class="errors.password && v$.password.$error ? 'error-border' : ''"
        v-model.trim="v$.password.$model"
        ref="signin-password"
        @keypress.enter="authorization"
      >
      <Icon
        name="eye"
        class="input-block__counter"
        :color="eyeColor"
        @mousedown="showPassword('signin-password')"
        @mouseup="showPassword('signin-password')"
        @mouseout="hidePassword"
        @mouseover="eyeColor = '#A0B2BE'"
      />
    </div>
    <div class="error" v-if="errors.password && v$.password.$error">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-show="v$.password.minLength.$invalid">
          Password must have at least {{v$.password.minLength.$params.min}} letters.
        </span>
        <span v-show="v$.password.maxLength.$invalid">
          Password must have no more than {{v$.password.maxLength.$params.max}} letters.
        </span>
      </div>
    </div>
    <div class="error" v-if="serverErrors.length">
      <Icon name="warning" class="mr-13"/>
      <div class="d-flex flex-column align-start">
        <span v-for="error in serverErrors" :key="error">{{error}}</span>
      </div>
    </div>
    <div class="registration-footer mt-32">
      <button
        id="sign-in-btn"
        class="primary-btn typography-base w-sm-100"
        :disabled="emptyFields || loading"
        @click="authorization"
      >
        Sign in
      </button>
      <a id="forgot-password" href="#" class="w-100 text-center results-btn" @click="resetPassword">
        Forgot password?
      </a>
    </div>
  </div>
</template>

<script>
import { minLength, maxLength, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Icon from '../app/Icon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginForm',
  components: {
    Icon
  },
  emits: ['showConfirmForm', 'resetPassword'],
  data () {
    return {
      email: '',
      password: '',
      serverErrors: [],
      loading: false,
      v$: useVuelidate(),
      errors: {
        email: false,
        password: false
      },
      eyeColor: ''
    };
  },
  validations () {
    return {
      email: {
        email,
        maxLength: maxLength(320)
      },
      password: {
        minLength: minLength(8),
        maxLength: maxLength(64)
      }
    };
  },
  computed: {
    ...mapGetters(['showConfirmEmailBlock']),
    emptyFields () {
      return !this.email || !this.password;
    }
  },
  methods: {
    hidePassword () {
      this.$refs['signin-password'].type = 'password';
      this.eyeColor = '';
    },
    showPassword (elem) {
      this.$refs[elem].type === 'password'
        ? this.$refs[elem].type = 'text'
        : this.$refs[elem].type = 'password';
    },
    resetPassword () {
      this.$emit('resetPassword');
    },
    checkErrorsForShow () {
      this.errors.email = false;
      this.errors.password = false;

      this.errors.email = this.v$.email.$error;
      this.errors.password = this.v$.password.$error;
      if (this.errors.email || this.errors.password) {
        return 'error';
      }
    },
    async authorization () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      try {
        this.loading = true;
        this.serverErrors = [];
        const body = {
          login: this.email,
          password: this.password
        };
        const subscription = await this.$store.dispatch('authorization', body);
        if (subscription) {
          this.$router.push({ path: '/' });
        } else {
          try {
            await this.$store.dispatch('checkConfirmInformation');
            this.$router.push({ path: '/' });
          } catch (e) {
            if (e.response.data.fields) {
              if (e.response.data.fields.email && e.response.data.fields.email === 'business_email_required') {
                this.$store.commit('setShowConfirmEmailBlock', true);
                this.$store.commit('setShowChangeEmailForm', true);
              }
              if (e.response.data.fields.email && e.response.data.fields.email === 'verification_required') {
                this.$store.commit('setShowConfirmEmailBlock', true);
                this.$store.commit('setShowEmailCodeForm', true);
                this.$store.dispatch('sendConfirmEmail');
              }
              if (e.response.data.fields.phone && e.response.data.fields.phone === 'required') {
                this.$store.commit('setShowConfirmPhoneBlock', true);
                this.$store.commit('setShowChangePhoneForm', true);
              }
              if (e.response.data.fields.phone && e.response.data.fields.phone === 'verification_required') {
                this.$store.commit('setShowConfirmPhoneBlock', true);
                this.$store.commit('setShowPhoneCodeForm', true);
                if (!this.showConfirmEmailBlock) {
                  this.$store.dispatch('sendConfirmPhone');
                }
              }
              this.$emit('showConfirmForm');
            } else {
              this.$router.push({ path: '/' });
            }
          }
        }

        this.loading = false;
        this.errors.email = false;
        this.errors.password = false;
      } catch (error) {
        this.loading = false;
        const res = error.response;
        if (res.status === 400 && Object.keys(res.data.fields).length) {
          for (const prop in res.data.fields) {
            res.data.fields[prop].forEach(item => {
              this.serverErrors.push(item.detail);
            });
          }
        } else {
          this.serverErrors.push('Something went wrong, try again later');
        }
      }
    }
  }
};
</script>

<template>
  <ConfirmEmailWithCode v-if="showConfirmEmailBlock" />
  <ConfirmAndChangePhone v-else-if="showConfirmPhoneBlock" />
</template>

<script>
import ConfirmAndChangePhone from './ConfirmAndChangePhone.vue';
import ConfirmEmailWithCode from './ConfirmEmailWithCode.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmSlAreaUsers',
  components: {
    ConfirmEmailWithCode,
    ConfirmAndChangePhone
  },
  computed: {
    ...mapGetters(['showConfirmPhoneBlock', 'showConfirmEmailBlock'])
  }
};
</script>
